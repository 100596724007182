<template>
  <div class="flex flex-row justify-content-center" :class="missionCreationMode ? '' : 'w-9'">
    <div class="flex flex-column">
      <div class="text-center">
        <p class="text-2xl">{{ step.label }}</p>
      </div>
      <!--        {{ v$?.localMissions?.$each[currentMission] }}-->
      <!--        {{ missionsDefined }}-->
      <!--        {{ missionsDefined && v$.localMissions }}-->
      <div class="flex flex-column cards card-tile" style="gap: 1rem;">
        <Card>
          <template #title>
            <p class="m-0 mb-2 p-0 text-xl font-bold line-height-3 mr-3">Client contractuel</p>
          </template>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div :class="v$.localMission.metadata.clientContract.name.$dirty && v$.localMission.metadata.clientContract.name.$errors.length ? 'mb-1' : ''"
                   class="field mb-4 col-12 md:col-6">
                <label for="social-raison" :class="{'p-error':v$.localMission.metadata.clientContract.name.$errors.length}">
                  Raison sociale *
                </label>
                <InputText id="social-raison" v-model="v$.localMission.metadata.clientContract.name.$model" type="text"
                    :class="{'p-invalid':v$.localMission.metadata.clientContract.name.$errors.length}"/>
              </div>
              <div v-if="v$.localMission.metadata.clientContract.name.$errors.length" class="mb-4 col-12">
                <span v-for="(error, index) of v$.localMission.metadata.clientContract.name.$errors" :key="index">
                  <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                </span>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="siret">SIRET</label>
                <InputText id="siret" type="text" v-model="localMission.metadata.clientContract.siret"/>
              </div>
            </div>
          </template>
        </Card>
        <Card>
          <template #title>
            <p class="m-0 mb-2 p-0 text-xl font-bold line-height-3 mr-3">Contact client contractuel</p>
          </template>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div :class="v$.localMission.metadata.clientContact.fullName.$dirty && v$.localMission.metadata.clientContact.fullName.$errors.length ? 'mb-1' : ''"
                   class="field mb-4 col-12 md:col-12">
                <label for="contact-client-contract-fullName" :class="{'p-error':v$.localMission.metadata.clientContact.fullName.$errors.length}">
                  Prénom et nom du contact *
                </label>
                <InputText id="contact-client-contract-fullName" v-model="v$.localMission.metadata.clientContact.fullName.$model"
                    type="text" :class="{'p-invalid':v$.localMission.metadata.clientContact.fullName.$errors.length}"
                />
              </div>
              <div v-if="v$.localMission.metadata.clientContact.fullName.$errors.length" class="mb-4 col-12">
                <span v-for="(error, index) of v$.localMission.metadata.clientContact.fullName.$errors" :key="index">
                  <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                </span>
              </div>
              <div class="field mb-4 col-12 md:col-6"
                   :class="v$.localMission.metadata.clientContact.phone.$dirty && v$.localMission.metadata.clientContact.phone.$errors.length ? 'mb-1' : ''">
                <label for="contact-client-contract-phone"
                       :class="{'p-error':v$.localMission.metadata.clientContact.phone.$errors.length}">
                  Téléphone *
                </label>
                <InputMask
                    id="contact-client-contract-phone"
                    v-model="v$.localMission.metadata.clientContact.phone.$model"
                    mask="99-99-99-99-99"
                    :class="{'p-invalid':v$.localMission.metadata.clientContact.phone.$errors.length}"
                />
              </div>
              <div v-if="v$.localMission.metadata.clientContact.phone.$errors.length" class="mb-4 col-12">
                <span v-for="(error, index) of v$.localMission.metadata.clientContact.phone.$errors" :key="index">
                  <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                </span>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="contact-client-contract-email">Email</label>
                <InputText id="contact-client-contract-email" type="text"
                           v-model="localMission.metadata.clientContact.email"/>
              </div>
            </div>
          </template>
        </Card>
        <Card>
          <template #title>
            <div class="flex align-items-center" style="gap: 0.5rem;">
              <p class="m-0 p-0 text-xl font-bold line-height-3 mr-3">Client final</p>
              <Checkbox :binary="true" id="mission-finalClient" type="text" v-model="isMissionFinalClientDifferent"/>
              <label for="mission-finalClient" class="text-sm">Différent</label>
            </div>
          </template>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12 md:col-6">
                <label for="mission-finalClient-fullname">Nom</label>
                <InputText id="mission-finalClient-fullname" type="text" v-model="localMission.jsonClientFinal.fullName"
                           :disabled="!isMissionFinalClientDifferent"/>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="mission-finalClient-address">Adresse client final</label>
                <InputText id="mission-finalClient-address" type="text" v-model="localMission.jsonClientFinal.address"
                           :disabled="!isMissionFinalClientDifferent"/>
              </div>
            </div>
          </template>
        </Card>
        <Card>
          <template #title>
            <div :class="v$.localMission.jsonCost.negotiatedRateUnit.$dirty && v$.localMission.jsonCost.negotiatedRateUnit.$errors.length ? 'mb-1' : ''"
                 class="flex align-items-center gap-1">
              <label :class="{'p-error':v$.localMission.jsonCost.negotiatedRateUnit.$errors.length}"
                     class="m-0 p-0 text-xl font-bold line-height-3 mr-3">
                Tarification
              </label>
              <div id="jsonCostNegotiatedRate">
                <Dropdown
                    v-model="v$.localMission.jsonCost.negotiatedRateUnit.$model"
                    class="dropdown-units"
                    :options="constants.data.negotiatedRateUnitOptions"
                    :class="{'p-invalid':v$.localMission.jsonCost.negotiatedRateUnit.$errors.length}"
                    @update:modelValue="negotiatedRateUnitChange"
                />
              </div>
            </div>
            <div v-if="v$.localMission.jsonCost.negotiatedRateUnit.$errors.length" class="col-12">
              <span v-for="(error, index) of v$.localMission.jsonCost.negotiatedRateUnit.$errors" :key="index">
                <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
              </span>
            </div>
          </template>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field col-12 mb-4">
                <label>Télétravail</label>
                <div class="flex justify-content-evenly">
                  <div class="flex disabled-radio-absolute gap-1 align-items-center">
                    <label for="mission-teleworking-yes">100 %</label>
                    <RadioButton id="mission-teleworking-yes" :value="constants.TELEWORKING_TYPE.FULL"
                                 v-model="localMission.jsonCost.teleworking" @update:modelValue="teleworkingTypeChange"/>
                  </div>
                  <div class="flex disabled-radio-absolute gap-1 align-items-center">
                    <label for="mission-teleworking-partial">Partiel</label>
                    <RadioButton id="mission-teleworking-partial" :value="constants.TELEWORKING_TYPE.PARTIAL"
                                 v-model="localMission.jsonCost.teleworking" @update:modelValue="teleworkingTypeChange"/>
                  </div>
                  <div class="flex disabled-radio-absolute gap-1 align-items-center">
                    <label for="mission-teleworking-no">0 %</label>
                    <RadioButton id="mission-teleworking-no" :value="constants.TELEWORKING_TYPE.NONE"
                                 v-model="localMission.jsonCost.teleworking" @update:modelValue="teleworkingTypeChange"/>
                  </div>
                </div>
              </div>
              <div v-if="localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE"
                   :class="v$.localMission.jsonCost.forfaitFacturationMode.$dirty && v$.localMission.jsonCost.forfaitFacturationMode.$errors.length ? 'mb-1' : ''"
                   class="field">
                <label for="forfaitFacturationMode" :class="{'p-error':v$.localMission.jsonCost.forfaitFacturationMode.$errors.length}">
                  Facturation *
                </label>
                <div id="forfaitFacturationMode" class="flex justify-content-evenly gap-1 align-items-center">
                  <div class="flex disabled-radio-absolute gap-1">
                    <label for="forfaitFacturationModeMonth">Mensuel</label>
                    <RadioButton
                        id="forfaitFacturationModeMonth"
                        v-model="v$.localMission.jsonCost.forfaitFacturationMode.$model" name="value"
                        :value="constants.INVOICE_TYPE.MONTHLY"
                        :class="{'p-invalid':v$.localMission.jsonCost.forfaitFacturationMode.$errors.length}"
                    />
                  </div>
                  <div class="flex disabled-radio-absolute gap-1">
                    <label for="forfaitFacturationModeOther">Autre</label>
                    <RadioButton
                        id="forfaitFacturationModeOther"
                        v-model="v$.localMission.jsonCost.forfaitFacturationMode.$model" name="value"
                        :value="constants.INVOICE_TYPE.OTHER"
                        :class="{'p-invalid':v$.localMission.jsonCost.forfaitFacturationMode.$errors.length}"
                    />
                  </div>
                </div>
              </div>
              <div v-if="v$.localMission.jsonCost.forfaitFacturationMode.$errors.length" class="mb-4 col-12">
                <span v-for="(error, index) of v$.localMission.jsonCost.forfaitFacturationMode.$errors" :key="index">
                  <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                </span>
              </div>

              <div v-if="(localMission.jsonCost.teleworking !== constants.TELEWORKING_TYPE.NONE && localMission.jsonCost.teleworking !== null)
                 && localMission.jsonCost.negotiatedRateUnit !== constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE"
                   class="field mb-4 col-12 md:col-12">
                <label for="cost-on-remote">
                  {{
                    (localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
                        ? 'Tarif journalier' : (localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE
                            ? 'Tarif forfaitaire' : 'Tarif horaire')) + ' télétravail'
                  }}
                </label>
                <InputNumber
                    id="cost-on-remote"
                    v-model="localMission.jsonCost.teleworkingRate"
                    :min="0"
                    locale="fr-FR"
                    mode="currency"
                    currency="EUR" highlightOnFocus
                    @update:modelValue="teleworkingRateChange"
                />
              </div>

              <!--  show this in all UNIT cases  -->
              <div :class="v$.localMission.jsonCost.negotiatedRate.value.$dirty && v$.localMission.jsonCost.negotiatedRate.value.$errors.length ? 'mb-1' : ''"
                   class="field mb-4 col-12 md:col-12">
                <label for="mission-tjm"
                       :class="{'p-error':v$.localMission.jsonCost.negotiatedRate.value.$errors.length}">
                  {{
                    (localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
                        ? 'Tarif journalier' : (localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE
                            ? 'Tarif forfaitaire' : 'Tarif horaire')) + (localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE ? '' : ' sur site')
                  }}
                </label>
                <InputNumber
                    id="mission-tjm"
                    v-model="v$.localMission.jsonCost.negotiatedRate.value.$model"
                    locale="fr-FR"
                    mode="currency"
                    currency="EUR" highlightOnFocus
                    :class="{'p-invalid':v$.localMission.jsonCost.negotiatedRate.value.$errors.length}"
                    @update:modelValue="negotiatedRateChange"
                />
              </div>
              <div v-if="v$.localMission.jsonCost.negotiatedRate.value.$errors.length" class="col-12">
                <span v-for="(error, index) of v$.localMission.jsonCost.negotiatedRate.value.$errors" :key="index">
                  <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
                </span>
              </div>
            </div>
          </template>
        </Card>
        <Card>
          <template #title>
            <p class="m-0 p-0 text-xl font-bold line-height-3 mr-3">Apporteur d'affaire</p>
          </template>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12 md:col-6">
                <label for="mission-tjm">Nom prénom</label>
                <InputText id="mission-affair-fullName" type="text"
                           v-model="localMission.jsonProvider.fullName"/>
              </div>
              <div class="field mb-4 col-12 md:col-6">
                <label for="mission-affair-payment">Paiement</label>
                <InputNumber id="mission-affair-payment" v-model="localMission.jsonProvider.payment.value"
                             locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus/>
              </div>
            </div>
          </template>
        </Card>
        <Card>
          <template #title>
            <p class="m-0 mb-2 p-0 text-xl font-bold line-height-3 mr-3">Mission</p>
          </template>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div :class="(v$.localMission.jsonMission.startingDate.$dirty && v$.localMission.jsonMission.startingDate.$errors.length) ? 'mb-1' : ''"
                   class="field mb-4 col-12 md:col-12">
                <label for="mission-startDate" :class="{'p-error': v$.localMission.jsonMission.startingDate.$errors.length}">
                  Date de démarrage *
                </label>
                <Calendar
                    id="mission-startDate"
                    v-model="v$.localMission.jsonMission.startingDate.$model"
                    date-format="dd/mm/yy"
                    :showIcon="true"
                    :manual-input="false"
                    :min-date="getStartingDateMinDate"
                    :class="{'p-invalid':v$.localMission.jsonMission.startingDate.$errors.length}"
                    @update:modelValue="startingDateChange"
                />
              </div>
              <div v-if="v$.localMission.jsonMission.startingDate.$errors.length" class="col-12">
                <span v-for="(error, index) of v$.localMission.jsonMission.startingDate.$errors" :key="index">
                  <InlineMessage class="w-full mb-1">
                    {{error.$message}}
                  </InlineMessage>
                </span>
              </div>
            </div>
          </template>
        </Card>
        <Card>
          <template #content>
            <p class="m-0 mb-2 p-0 text-xl font-bold line-height-3 mr-3">Commentaires</p>
            <Textarea style="width: 100%; border-radius: 10px" :autoResize="true" rows="5"
                      v-model="localMission.notes"></Textarea>
          </template>
        </Card>
        <Divider></Divider>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {mapState} from "vuex";
import UtilsDate from "@/utils/Date";

export default {
  name: "MissionForm",
  components: {},
  setup: () => ({v$: useVuelidate()}), // { $rewardEarly: false }
  props: {
    mission: {
      type: Object,
      required: true
    },
    step: {
      type: Object,
      required: true
    },
    missionCreationMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localMission: this.mission,
      isMissionFinalClientDifferent: false,

      isFetchingNegotiatedRateValues: false,
      isFetchingManagementFeeValues: false,

      previousUpdatedStartingDate: null
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    getStartingDateMinDate () {
      // the prospect cannot enter a date that is 10 days before the current one
      let minDate = new Date()
      minDate = new Date(minDate.setDate(minDate.getDate() - 10))
      return minDate
    }
  },
  watch: {
    'mission': {
      handler () {
        this.localMission = JSON.parse(JSON.stringify(this.mission))
        this.initDates()
      },
      deep: true
    }
  },
  validations() {
    const form = {
      localMission: {
        jsonMission: {
          startingDate: {required: helpers.withMessage('Date de début obligatoire', required)}
        },
        metadata: {
          clientContract: {
            name: {required: helpers.withMessage('Raison sociale du client obligatoire', required)}
          },
          clientContact: {
            fullName: {required: helpers.withMessage('Nom complet du contact client obligatoire', required)},
            phone: {required: helpers.withMessage('Téléphone du contact client obligatoire', required)}
          }
        },
        jsonCost: {
          negotiatedRateUnit: {required: helpers.withMessage('Choix de la tarification obligatoire', required)},
          negotiatedRate: {
            value: {required: helpers.withMessage('Choix du prix de la tarification obligatoire', required)}
          },
          forfaitFacturationMode: {}
        }
      }
    }
    if (this.localMission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE) {
      form.localMission.jsonCost.forfaitFacturationMode = {required: helpers.withMessage('Choix d\'un mode de facturation obligatoire', required)}
    }

    return form
  },
  created () {
    this.localMission = JSON.parse(JSON.stringify(this.mission))
    this.initDates()
  },
  methods: {
    initDates () {
      this.previousUpdatedStartingDate = this.localMission.jsonMission.startingDate ? JSON.parse(JSON.stringify(this.localMission.jsonMission.startingDate)) : null
      this.localMission.jsonMission.startingDate = this.localMission.jsonMission.startingDate ? UtilsDate.getDate(new Date(this.localMission.jsonMission.startingDate)) : null
    },
    async onSubmit () {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return { validate: false }
      }
      this.$store.state.misc.loading = true

      // force to init in case of not initialized at all
      await this.updateOriginalNegotiatedRateValues(true)

      this.$store.state.misc.loading = false
      return { validate: true, mission: this.localMission }
    },
    async updateOriginalNegotiatedRateValues (init = false) {
      this.isFetchingNegotiatedRateValues = true
      this.localMission.jsonCost.negotiatedRateValues = await this.$store.dispatch('mission/getDefaultNegotiatedRateValues', {
        previousUpdatedStartingDate: this.previousUpdatedStartingDate,
        mission: this.localMission,
        init,
        useDefaultNegotiatedRate: true,
      })
      this.isFetchingNegotiatedRateValues = false
    },
    async updateOriginalManagementFeeValues (init = false) {
      this.isFetchingManagementFeeValues = true
      this.localMission.jsonCost.managementFeeValues = await this.$store.dispatch('mission/getDefaultManagementFeeValues', {
        previousUpdatedStartingDate: this.previousUpdatedStartingDate,
        mission: this.localMission,
        init,
        useDefaultNegotiatedRate: true
      })
      this.isFetchingManagementFeeValues = false
    },
    // eslint-disable-next-line no-unused-vars
    negotiatedRateUnitChange (value) {
      // update the current rates with the existing negotiatedRates
      let costOnRemoteValue = null
      let costOnSiteValue = null
      let negotiatedRateValue

      if (this.localMission?.jsonCost?.negotiatedRateValues && this.localMission?.jsonCost?.negotiatedRateValues.length) {
        negotiatedRateValue = this.localMission?.jsonCost?.negotiatedRateValues.find(negotiatedRate => negotiatedRate.unit === this.localMission.jsonCost.negotiatedRateUnit)
      }

      if (negotiatedRateValue !== undefined) {
        costOnRemoteValue = negotiatedRateValue.costOnRemote
        costOnSiteValue = negotiatedRateValue.costOnSite
      }
      this.localMission.jsonCost.teleworkingRate = costOnRemoteValue
      this.localMission.jsonCost.negotiatedRate.value = costOnSiteValue
    },
    async teleworkingTypeChange () {
      await this.updateOriginalNegotiatedRateValues()
      await this.presetTeleworkingRate()
    },
    async presetTeleworkingRate () {
      if (this.localMission.jsonCost.teleworkingRate) return
      switch (this.localMission.jsonCost.teleworking) {
        case this.constants.TELEWORKING_TYPE.PARTIAL:
          this.localMission.jsonCost.teleworkingRate = this.localMission.jsonCost.negotiatedRate.value
          break
        case this.constants.TELEWORKING_TYPE.FULL:
          this.localMission.jsonCost.teleworkingRate = this.localMission.jsonCost.negotiatedRate.value
          break
        default:
          this.localMission.jsonCost.teleworkingRate = null
      }
    },
    async teleworkingRateChange () {
      await this.updateOriginalNegotiatedRateValues()
    },
    async negotiatedRateChange () {
      await this.updateOriginalNegotiatedRateValues()
    },
    async startingDateChange () {
      await this.updateOriginalNegotiatedRateValues(true)
      await this.updateOriginalManagementFeeValues(true)
      // might do it after the save only ?
      this.previousUpdatedStartingDate = JSON.parse(JSON.stringify(this.localMission.jsonMission.startingDate))
    }
  }
}
</script>

<style scoped>

</style>
